import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink:true,
        linkDetails:{
            label:'Australian Made',
            url:`${metadata.products.link}/australian-made-furniture`,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:'Bedroom',
            url:`${metadata.products.link}/bedroom`,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:'Living',
            url:`${metadata.products.link}/living`,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:'Dining',
            url:`${metadata.products.link}/dining`,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:'On sale',
            url:`${metadata.products.link}/on-sale`,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.contact.linkName,
            url:metadata.contact.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.about.linkName,
            url:metadata.about.route,
        }
    },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.home.linkName,
    //         url:metadata.home.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.products.linkName,
    //         url:metadata.products.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.packages.linkName,
    //         url:metadata.packages.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.testimonials.linkName,
    //         url:metadata.testimonials.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.faqs.linkName,
    //         url:metadata.faqs.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.blogs.linkName,
    //         url:metadata.blogs.route,
    //     }
    // },

    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.glossary.linkName,
    //         url:metadata.glossary.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.portfolio.linkName,
    //         url:metadata.portfolio.route,
    //     }
    // }
]




const renderInternalNavLinks = () =>{

    //render each internal link that is in the headerLinks array
    let footerNavLinks =[]
    for (const navLink of headerLinks) {
        if(navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type:'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns:[
        {
            content:[
                {
                    type:'title',
                    value:APP_CONFIG.WEBSITE_NAME
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                // {
                //     type:'title',
                //     value:headerLinks[0].linkDetails.label
                // },
                // ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                // {
                //     type:'title',
                //     value:headerLinks[1].linkDetails.label
                // },
                // ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                {
                    type:'img',
                    specialClass:'footerLogo',
                    value:{
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]

        }
    ]
}
