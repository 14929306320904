import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#be9600',
    },
    secondary: {
      main: '#333333',
    },
    background: {
      default: '#ffffff',
    },
  },
  shadows: ["none"],
  components: {
    MuiDialog:{
      styleOverrides:{
        paperWidthMd:{
          width:'320px',
          '&.searchBoxContainer':{
            position:'initial',
          }
        },
      },
    },
    MuiOutlinedInput:{
      styleOverrides: {
        adornedStart: {
          backgroundColor: '#f1f1f1',
          color:"#444444",
          width: '280px',
          borderRadius:'11px',
          gap: '16px',
          padding: '0 16px',
          '& input':{
            padding:'11px 14px',
            '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: '#979797',
              opacity: 1, /* Firefox */
            },

            '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
              color: '#979797',
            },

            '&::-ms-input-placeholder': { /* Microsoft Edge */
              color: '#979797',
            },
          },
          '& fieldset':{
            border: 'none'
          },
        },
      },
    },
    MuiIconButton:{
      styleOverrides:{
        colorPrimary:{
          minWidth:'64px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text:{
          color:'black',
          fontFamily: 'CoreSansA45Regular',
          padding: defaultTheme.spacing(1, 2),
          '&:hover':{
            color:'black',
          },
          cursor:'pointer',
        },
        contained:{
          paddingLeft: defaultTheme.spacing(5),
          paddingRight: defaultTheme.spacing(5),
          cursor:'pointer',
        },
        root: {
          // Some CSS
          color:'white',
          padding: defaultTheme.spacing(1.7, 2),
          borderRadius: 0,
          fontFamily: 'CoreSansA55Medium',
          textTransform: 'none',
          fontSize:'18px'
        },
      },
      disableElevation: true,
      defaultProps: {
        disableElevation: true,
        size:'large',

      },
    },
  },
});


export default theme;
