import React from 'react'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import history from '../history'
import { metadata } from '../app-config'

import {
    Landing,
    Contact,
    About,
    SearchPage,
    GalleryPage,
    TestimonialsPage,
    BlogsPage,
    BlogPage,
    FaqsPage,
    FaqPage,
    ProductsPage,
    ProductPage,
    PackagesPage,
    PackagePage,
    SpecialOffersPage,
    SpecialOfferPage,
} from '../loader'

const Routes = () =>{
    return(
        <div className="bodyContentContainer">
        <Router history={history}>
            <div>
                <Switch>
                    {/*<Redirect from="/" exact to={metadata.home.route} />*/}
                        <Route path={metadata.home.route} exact component={Landing} />
                        <Route path={metadata.gallery.route} exact component={GalleryPage} />
                        <Route path={metadata.contact.route} exact component={Contact} />
                        <Route path={metadata.about.route} exact component={About} />
                        <Route path={metadata.search.route} exact component={SearchPage} />
                        <Route path={metadata.testimonials.route} exact component={TestimonialsPage} />
                        <Route path={metadata.blogs.route} exact component={BlogsPage} />
                        <Route path={metadata.blog.route} exact component={BlogPage} />
                        <Route path={metadata.faqs.route} exact component={FaqsPage} />
                        <Route path={metadata.faq.route} exact component={FaqPage} />
                        <Route path={metadata.packages.route} exact component={PackagesPage} />
                        <Route path={metadata.package.route} exact component={PackagePage} />
                        <Route path={metadata.products.route}  component={ProductsPage} />
                        <Route path={metadata.product.route} exact component={ProductPage} />
                    <Route path={metadata.specialOffers.route} exact component={SpecialOffersPage} />
                    <Route path={metadata.specialOffer.route} exact component={SpecialOfferPage} />
                </Switch>
             </div>
        </Router>
    </div>
);
};
export default Routes;
