import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-2'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-1',  fill: '#FFFFFF' }, 0)
        .add({ targets: '.large-select-0',  fill: '#d5d7d9' }, 0)

    },[])



    //render() {
        return (
          <div className="loaderContainer" >
            <div className="small-loading-icon-container" style={{ width: '200px', height: '50px', top: '20px' }}>
              <svg version="1.1" x="0px" y="0px" width="170.5px"
                   height="41.2px" viewBox="0 0 170.5 41.2" >

                <g>
	<g>
		<path className="large-select-0" d="M22.4,40.9c0-0.1,0-0.3,0-0.4c0-0.5,0.1-0.9,0.2-1.4c0.1-0.6,0.1-1.2,0.2-1.8s0.1-1.2,0.2-1.7
			c0.1-0.6,0.1-1.2,0.2-1.8s0.1-1.2,0.2-1.7c0.1-0.6,0.1-1.2,0.2-1.9c0.1-0.6,0.1-1.2,0.2-1.8s0.1-1.2,0.2-1.8
			c0.1-0.5,0.1-0.9,0.1-1.4c0.1-0.6,0.1-1.2,0.2-1.8c0-0.4,0.1-0.9,0.1-1.3c0.1-0.6,0.1-1.3,0.2-1.9s0.1-1.2,0.2-1.8
			c0-0.1,0-0.2,0-0.3c-0.8,0-1.6,0-2.5,0c-0.1,0-0.1,0-0.2,0c0-0.4,0.1-0.7,0.1-1.1c0.1-0.6,0.1-1.1,0.2-1.7
			c0.1-0.5,0.1-1.1,0.2-1.6c0-0.2,0.1-0.2,0.2-0.2c0.8,0,1.5,0,2.3,0c0.2,0,0.2,0,0.3-0.2c0.1-0.5,0.1-1,0.2-1.6
			c0.1-0.5,0.1-1.1,0.2-1.6c0-0.4,0.1-0.8,0.2-1.2c0-0.2,0.1-0.3,0.1-0.5c0.2-0.5,0.3-1,0.5-1.5c0.3-0.6,0.6-1.2,1-1.8
			c0.9-1.1,2-1.8,3.3-2.1C28,1.1,24.4,0,20.5,0C9.2,0,0,9.2,0,20.5c0,5,1.8,9.6,4.7,13.1c0.1-0.7,0.2-1.4,0.3-2.1
			c0.1-0.9,0.2-1.9,0.3-2.8c0.1-0.9,0.2-1.9,0.3-2.8c0.1-0.8,0.2-1.6,0.3-2.4c0.1-0.9,0.2-1.9,0.3-2.8c0.1-0.8,0.2-1.6,0.3-2.4
			c0.1-0.9,0.2-1.9,0.3-2.8C6.9,15,6.9,14.4,7,13.9c0-0.1,0.1-0.2,0.2-0.2l0,0c1.3,0,2.5,0,3.8,0c0.2,0,0.3,0,0.5,0s0.2,0.1,0.1,0.2
			c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.8-0.1,1.2c0.8-1,1.9-1.7,3.1-2.1c0.4-0.1,0.9-0.2,1.3-0.2c1-0.1,2,0.1,2.9,0.4
			c0.3,0.1,0.6,0.2,0.9,0.3l0.1,0.1v0.1c-0.3,1-0.6,1.9-0.9,2.9c-0.2,0.5-0.4,1.1-0.7,1.6c-0.1,0.1-0.1,0.2-0.3,0.1
			c-0.7-0.3-1.4-0.4-2.2-0.5c-1.3-0.1-2.5,0-3.5,0.7c-0.8,0.5-1.4,1-1.5,2c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8
			c-0.1,0.6-0.1,1.2-0.2,1.7c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.2-0.2,1.7
			c-0.1,0.6-0.1,1.2-0.2,1.8C9,35.3,9,35.9,8.9,36.4c0,0.4-0.1,0.8-0.1,1.2c3.3,2.2,7.3,3.6,11.5,3.6C21.2,41,21.8,41,22.4,40.9z"/>
    <path className="large-select-0" d="M41,20.5c0-5.1-1.9-9.8-5-13.4C35.3,7,34.1,7,33.1,7.2c-0.7,0.2-1.2,0.6-1.6,1.2c-0.3,0.5-0.5,1.1-0.6,1.7
			s-0.1,1.2-0.2,1.7c-0.1,0.5-0.1,0.9-0.1,1.4c1.5,0,3-0.1,4.5-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.8-0.1,1.2
			c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.2-0.1,0.3-0.3,0.3c-1.3,0-2.7,0-4,0c-0.2,0-0.3,0.1-0.3,0.3
			c0,0.2,0,0.4-0.1,0.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.2-0.2,1.7c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.2-0.2,1.8
			c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.2-0.2,1.7c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.7-0.2,1.3-0.3,1.9s-0.1,1.2-0.2,1.8
			S28,36,27.9,36.6s-0.1,1.2-0.2,1.8c-0.1,0.5-0.1,0.9-0.2,1.4C35.4,36.9,41,29.4,41,20.5z"/>
    <path className="large-select-1" d="M5.5,32.1c0.1-0.4,0.1-0.9,0.2-1.3c0-0.4,0.1-0.8,0.1-1.3C5.9,29,5.9,28.6,6,28.1c0-0.4,0.1-0.8,0.1-1.3
			c0.1-0.4,0.1-0.9,0.2-1.3c0-0.4,0.1-0.8,0.1-1.3c0.1-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.1-0.9,0.1-1.3c0.1-0.5,0.1-0.9,0.2-1.4
			C6.9,19.8,7,19.4,7,19c0.1-0.4,0.1-0.9,0.2-1.3c0-0.4,0.1-0.8,0.1-1.3c0.1-0.5,0.1-0.9,0.2-1.4c0-0.4,0.1-0.8,0.2-1.3
			c1.3,0,2.7,0,4,0c0.1-0.2,0.1-0.2-0.1-0.2c-0.2,0-0.3,0-0.5,0c-1.3,0-2.5,0-3.8,0l0,0c-0.1,0-0.2,0-0.2,0.2
			C7,14.2,7,14.8,6.9,15.3c-0.1,0.9-0.2,1.9-0.3,2.8c-0.1,0.8-0.2,1.6-0.3,2.4c-0.1,0.9-0.2,1.9-0.3,2.8c-0.1,0.8-0.2,1.6-0.3,2.4
			c-0.1,0.9-0.2,1.9-0.3,2.8c-0.1,0.9-0.2,1.9-0.3,2.8C5,32,4.9,32.7,4.8,33.4C5,33.6,5.1,33.8,5.3,34c0-0.3,0.1-0.5,0.1-0.8
			C5.4,33,5.4,32.6,5.5,32.1z"/>
    <path className="large-select-1" d="M22.9,38c0.1-0.5,0.1-1,0.2-1.5c0-0.3,0.1-0.6,0.1-0.9c0.1-0.5,0.1-1,0.2-1.4c0-0.3,0.1-0.6,0.1-1
			c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.1-1.4c0.1-0.5,0.1-1,0.2-1.5c0-0.4,0.1-0.9,0.1-1.3c0.1-0.5,0.1-1,0.2-1.4
			c0-0.5,0.1-0.9,0.1-1.4c0.1-0.6,0.1-1.2,0.2-1.8c0.1-0.5,0.1-0.9,0.1-1.4c0.1-0.6,0.1-1.2,0.2-1.8c0.1-0.5,0.1-1,0.2-1.6
			c0-0.1,0.1-0.3-0.2-0.4c0,0.1,0,0.2,0,0.3c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.1,1.3-0.2,1.9c0,0.4-0.1,0.9-0.1,1.3
			c-0.1,0.6-0.1,1.2-0.2,1.8c0,0.5-0.1,0.9-0.1,1.4c-0.1,0.6-0.1,1.2-0.2,1.8s-0.1,1.2-0.2,1.8s-0.1,1.2-0.2,1.9
			c-0.1,0.6-0.1,1.2-0.2,1.7c-0.1,0.6-0.1,1.2-0.2,1.8S23,36.5,22.9,37c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.5-0.1,0.9-0.2,1.4
			c0,0.1,0,0.3,0,0.4c0.1,0,0.1,0,0.2,0c0-0.5,0.1-1,0.2-1.5C22.9,38.9,22.9,38.5,22.9,38z"/>
    <path className="large-select-1" d="M31.1,2.9c-1.4,0.3-2.5,1-3.3,2.1c-0.4,0.5-0.8,1.1-1,1.8c-0.2,0.5-0.3,1-0.5,1.5c0,0.2-0.1,0.3-0.1,0.5
			C26.1,9.2,26.1,9.6,26,10c-0.1,0.5-0.1,1.1-0.2,1.6s-0.1,1-0.2,1.6c0,0.2-0.1,0.2-0.3,0.2c-0.8,0-1.5,0-2.3,0
			c-0.1,0-0.2,0-0.2,0.2c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,0.6-0.1,1.1-0.2,1.7c0,0.4-0.1,0.7-0.1,1.1c0.1,0,0.2,0,0.2,0
			c0-0.3,0.1-0.6,0.1-0.9c0.1-0.5,0.1-1.1,0.2-1.6c0.1-0.6,0.1-1.2,0.2-1.8c0-0.1,0.1-0.2,0.2-0.2c0.6,0,1.2,0,1.7,0
			c0.2,0,0.5,0,0.7,0c0-0.1,0-0.2,0.1-0.3c0.1-0.5,0.1-1,0.2-1.6s0.1-1.1,0.2-1.7c0-0.3,0.1-0.7,0.2-1c0-0.2,0.1-0.3,0.1-0.5
			c0.2-0.6,0.4-1.1,0.6-1.7c0.4-0.9,1-1.7,1.8-2.3c0.7-0.6,1.6-1,2.5-1.2c0.1,0,0.2-0.1,0.2-0.1C31.4,3.1,31.2,3,31.1,2.9z"/>
    <path className="large-select-1" d="M12.5,15c0.4-0.3,0.8-0.6,1.2-0.8c1.2-0.8,2.5-1.1,3.9-0.9c0.8,0.1,1.6,0.3,2.3,0.6l-0.1-0.1
			c-0.3-0.1-0.6-0.2-0.9-0.3c-1-0.3-1.9-0.5-2.9-0.4c-0.4,0.1-0.9,0.1-1.3,0.2c-1.3,0.4-2.3,1.1-3.2,2c0.2,0.1,0.3,0.1,0.4,0.2
			C12.2,15.3,12.3,15.1,12.5,15z"/>
    <path className="large-select-1" d="M30.7,13.4c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.2,0c0-0.1,0-0.1,0-0.2c-1.5,0-3,0.1-4.5,0.1
			C30.6,13.4,30.6,13.4,30.7,13.4z"/>
	</g>
                  <g>
		<path className="large-select-2" d="M51,17.3v9h-4V3.9h8.2c1.9,0,3.9,0,5.6,1c2,1.2,3.2,3,3.2,5.7c0,2.5-1.2,4.3-3,5.5c-0.5,0.3-1.2,0.6-1.7,0.8
			l5.6,9.4h-4.7l-5.2-9C55,17.3,51,17.3,51,17.3z M51,13.8h4.3c1.6,0,2.5-0.1,3.2-0.4c1-0.5,1.6-1.6,1.6-2.8c0-1.2-0.6-2.3-1.6-2.7
			c-0.7-0.3-1.5-0.4-3.2-0.4H51V13.8z"/>
                    <path className="large-select-2" d="M72.1,10.3c4.8,0,7.8,3.2,7.8,8.3c0,5-3,8.2-7.8,8.2s-7.8-3.2-7.8-8.2C64.2,13.5,67.3,10.3,72.1,10.3z
			 M72.1,23.5c2.4,0,4.1-1.9,4.1-5c0-3.2-1.6-5-4.1-5s-4.1,1.9-4.1,5S69.7,23.5,72.1,23.5z"/>
                    <path className="large-select-2" d="M85.4,24.7v1.6h-3.8V3.9h3.8v8.4c0.9-1.1,2.6-2.1,4.6-2.1c4.3,0,7,3.4,7,8.3c0,4.7-2.8,8.2-7,8.2
			C87.9,26.7,86.4,25.9,85.4,24.7z M89.3,23.4c2.4,0,4-1.9,4-5c0-3.2-1.6-5-4-5s-4,2-4,5C85.3,21.5,86.9,23.4,89.3,23.4z"/>
                    <path className="large-select-2" d="M113.1,22.7c-0.7,1.1-2.8,4-7.4,4s-7.8-3.3-7.8-8.4c0-4.8,3.3-8.1,7.7-8.1c5,0,7.4,3.5,7.4,8.3
			c0,0.3,0,0.7,0,1h-11.4c0.3,2.1,1.6,4,4.4,4s4.1-1.9,4.6-2.7L113.1,22.7z M101.7,16.7h7.6c-0.1-2-1.6-3.4-3.7-3.4
			C103.5,13.3,101.8,14.8,101.7,16.7z"/>
                    <path className="large-select-2" d="M118.6,10.6v2.3c0.3-0.8,1.5-2.7,4.6-2.7c0.2,0,0.3,0,0.6,0V14c-0.4,0-0.7-0.1-1.1-0.1c-2,0-3.1,0.7-3.7,2.1
			c-0.3,0.8-0.4,1.7-0.4,3.1v7.1h-3.8V10.6H118.6z"/>
                    <path className="large-select-2" d="M135.3,26.4c-0.7,0.2-1.7,0.3-2.8,0.3c-1.5,0-3.1-0.3-4-1.4c-0.6-0.8-0.7-1.6-0.7-3.2v-8.6h-3.4v-2.9h3.4V5.1
			l3.8-0.8v6.3h3.7v2.9h-3.7v8c0,0.9,0.1,1.3,0.4,1.6c0.3,0.3,0.9,0.4,1.6,0.4s1.3-0.1,1.6-0.1L135.3,26.4L135.3,26.4z"/>
                    <path className="large-select-2" d="M143.3,10.3c4.8,0,7.8,3.2,7.8,8.3c0,5-3,8.2-7.8,8.2s-7.8-3.2-7.8-8.2C135.4,13.5,138.5,10.3,143.3,10.3z
			 M143.3,23.5c2.4,0,4.1-1.9,4.1-5c0-3.2-1.6-5-4.1-5s-4.1,1.9-4.1,5S140.9,23.5,143.3,23.5z"/>
                    <path className="large-select-2" d="M156.3,3.9v3.4c0,0.6,0,1.4-0.1,1.9c-0.2,2.4-1.1,3.9-4.2,4v-2.1c1.2-0.1,1.9-0.6,1.9-2.3V8.4H152V4.1h4.3
			V3.9z"/>
                    <path className="large-select-2" d="M167.8,15.1c-1.1-1.1-2.6-1.8-4.4-1.8c-1.5,0-2.6,0.5-2.6,1.5c0,1.1,1.1,1.4,3.3,1.8L166,17
			c2.4,0.6,4.5,1.5,4.5,4.3c0,3.6-3.3,5.3-6.9,5.3c-3.8,0-6.3-1.8-7.3-3.2l2.5-2.2c0.9,1.2,2.4,2.3,4.8,2.3c1.9,0,3-0.7,3-1.8
			c0-1.1-0.9-1.3-2.4-1.6l-2.3-0.5c-2.7-0.6-4.9-1.7-4.9-4.6c0-3.4,3.3-4.9,6.3-4.9c3.1,0,5.5,1.4,6.6,2.5L167.8,15.1z"/>
	</g>
                  <g>
		<path className="large-select-2" d="M47.6,30.8h6v1.1h-4.9v2.6H53v1h-4.3v4h-1.2L47.6,30.8L47.6,30.8z"/>
                    <path className="large-select-2" d="M62.9,30.8v5.4c0,0.6,0.1,1.2,0.4,1.6c0.4,0.6,0.9,0.9,1.8,0.9c1,0,1.7-0.3,2.1-1c0.2-0.4,0.3-0.9,0.3-1.5
			v-5.4h1.2v4.9c0,1.1-0.1,1.9-0.4,2.5c-0.5,1-1.5,1.6-3,1.6s-2.5-0.5-3-1.6c-0.3-0.6-0.4-1.4-0.4-2.5v-4.9H62.9z"/>
                    <path className="large-select-2" d="M77.1,30.8H81c0.7,0,1.2,0.1,1.6,0.3c0.8,0.4,1.2,1.1,1.2,2.1c0,0.5-0.1,0.9-0.3,1.3
			c-0.2,0.3-0.5,0.6-0.9,0.8c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1.1v1.2c0,0.3,0,0.6,0.1,0.7c0.1,0.3,0.2,0.5,0.4,0.5v0.2
			h-1.4c0-0.1-0.1-0.2-0.1-0.3s0-0.3-0.1-0.7l-0.1-1.4c0-0.6-0.2-0.9-0.6-1.1c-0.2-0.1-0.6-0.2-1-0.2h-2.6v3.7h-1.2
			C77.1,39.5,77.1,30.8,77.1,30.8z M81,34.8c0.5,0,1-0.1,1.3-0.3s0.5-0.6,0.5-1.2c0-0.6-0.2-1-0.7-1.3c-0.2-0.1-0.5-0.2-0.9-0.2
			h-2.8v3H81z"/>
                    <path className="large-select-2" d="M92.4,30.8h1.4l4.3,7v-7h1.1v8.7h-1.3l-4.4-7v7h-1.1V30.8z"/>
                    <path className="large-select-2" d="M108.1,30.8h1.2v8.7h-1.2V30.8z"/>
                    <path className="large-select-2" d="M124.2,30.8v1h-2.9v7.7h-1.2v-7.7h-2.9v-1H124.2z"/>
                    <path className="large-select-2" d="M133.2,30.8v5.4c0,0.6,0.1,1.2,0.4,1.6c0.4,0.6,0.9,0.9,1.8,0.9c1,0,1.7-0.3,2.1-1c0.2-0.4,0.3-0.9,0.3-1.5
			v-5.4h1.2v4.9c0,1.1-0.1,1.9-0.4,2.5c-0.5,1-1.5,1.6-3,1.6s-2.5-0.5-3-1.6c-0.3-0.6-0.4-1.4-0.4-2.5v-4.9H133.2z"/>
                    <path className="large-select-2" d="M147.5,30.8h3.9c0.7,0,1.2,0.1,1.6,0.3c0.8,0.4,1.2,1.1,1.2,2.1c0,0.5-0.1,0.9-0.3,1.3
			c-0.2,0.3-0.5,0.6-0.9,0.8c0.3,0.1,0.6,0.3,0.8,0.5s0.3,0.6,0.3,1.1v1.2c0,0.3,0,0.6,0.1,0.7c0.1,0.3,0.2,0.5,0.4,0.5v0.2h-1.4
			c0-0.1-0.1-0.2-0.1-0.3s0-0.3-0.1-0.7l-0.1-1.4c0-0.6-0.2-0.9-0.6-1.1c-0.2-0.1-0.6-0.2-1-0.2h-2.6v3.7h-1.2L147.5,30.8
			L147.5,30.8z M151.3,34.8c0.5,0,1-0.1,1.3-0.3s0.5-0.6,0.5-1.2c0-0.6-0.2-1-0.7-1.3c-0.2-0.1-0.5-0.2-0.9-0.2h-2.8v3H151.3z"/>
                    <path className="large-select-2" d="M162.9,30.8h6.3v1.1H164v2.6h4.8v1H164v2.9h5.2v1h-6.4L162.9,30.8L162.9,30.8z"/>
	</g>
</g>
</svg>

            </div>
          </div>
        )
    //}
}

export default BigLogoLoader
